{
  "data": {
    "url": "https://2zg3ff4ha5fz5lzlytj7vidopq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_region": "ap-southeast-1",
    "api_key": "da2-lv6i3ny7bna3jhbviyhvtm3l5y",
    "default_authorization_type": "API_KEY",
    "authorization_types": [
      "AWS_IAM"
    ],
    "model_introspection": {
      "version": 1,
      "models": {
        "Conversation": {
          "name": "Conversation",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "customerId": {
              "name": "customerId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "messages": {
              "name": "messages",
              "isArray": true,
              "type": {
                "model": "Message"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true,
              "association": {
                "connectionType": "HAS_MANY",
                "associatedWith": [
                  "conversationId"
                ]
              }
            },
            "socialChannelId": {
              "name": "socialChannelId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "totalUnread": {
              "name": "totalUnread",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "lastModified": {
              "name": "lastModified",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "Conversations",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "name": "conversationsBySocialChannelIdAndCreatedAt",
                "queryField": "listConversationBySocialChannelIdAndCreatedAt",
                "fields": [
                  "socialChannelId",
                  "createdAt"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "conversationsBySocialChannelIdAndUpdatedAt",
                "queryField": "listConversationBySocialChannelIdAndUpdatedAt",
                "fields": [
                  "socialChannelId",
                  "updatedAt"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "conversationsBySocialChannelIdAndLastModified",
                "queryField": "listConversationBySocialChannelIdAndLastModified",
                "fields": [
                  "socialChannelId",
                  "lastModified"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "Message": {
          "name": "Message",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "messageId": {
              "name": "messageId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "socialChannelId": {
              "name": "socialChannelId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "businessId": {
              "name": "businessId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "compId": {
              "name": "compId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isPageSender": {
              "name": "isPageSender",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "identityId": {
              "name": "identityId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "identityFullName": {
              "name": "identityFullName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "message": {
              "name": "message",
              "isArray": false,
              "type": {
                "nonModel": "MessageTemplate"
              },
              "isRequired": false,
              "attributes": []
            },
            "messageStatus": {
              "name": "messageStatus",
              "isArray": false,
              "type": {
                "enum": "MessageMessageStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "timeStamp": {
              "name": "timeStamp",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "conversationId": {
              "name": "conversationId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "conversation": {
              "name": "conversation",
              "isArray": false,
              "type": {
                "model": "Conversation"
              },
              "isRequired": false,
              "attributes": [],
              "association": {
                "connectionType": "BELONGS_TO",
                "targetNames": [
                  "conversationId"
                ]
              }
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": []
            }
          },
          "syncable": true,
          "pluralName": "Messages",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "name": "messagesByConversationIdAndCreatedAt",
                "queryField": "listMessageByConversationIdAndCreatedAt",
                "fields": [
                  "conversationId",
                  "createdAt"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "messagesByConversationIdAndUpdatedAt",
                "queryField": "listMessageByConversationIdAndUpdatedAt",
                "fields": [
                  "conversationId",
                  "updatedAt"
                ]
              }
            },
            {
              "type": "key",
              "properties": {
                "name": "messagesByConversationIdAndTimeStamp",
                "queryField": "listMessageByConversationIdAndTimeStamp",
                "fields": [
                  "conversationId",
                  "timeStamp"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "create",
                      "update",
                      "delete",
                      "read"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        }
      },
      "enums": {
        "MessageMessageStatus": {
          "name": "MessageMessageStatus",
          "values": [
            "sent",
            "unread",
            "read",
            "failed",
            "unknown"
          ]
        }
      },
      "nonModels": {
        "Attachment": {
          "name": "Attachment",
          "fields": {
            "payload": {
              "name": "payload",
              "isArray": false,
              "type": {
                "nonModel": "AttachmentPayload"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MessageTemplate": {
          "name": "MessageTemplate",
          "fields": {
            "mid": {
              "name": "mid",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "text": {
              "name": "text",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "attachments": {
              "name": "attachments",
              "isArray": true,
              "type": {
                "nonModel": "Attachment"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "contents": {
              "name": "contents",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "AttachmentPayload": {
          "name": "AttachmentPayload",
          "fields": {
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "previewUrl": {
              "name": "previewUrl",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "title": {
              "name": "title",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "address": {
              "name": "address",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "latitude": {
              "name": "latitude",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "longitude": {
              "name": "longitude",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fileName": {
              "name": "fileName",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "fileSize": {
              "name": "fileSize",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "packageId": {
              "name": "packageId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "stickerId": {
              "name": "stickerId",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "duration": {
              "name": "duration",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        }
      }
    }
  },
  "version": "1.3"
}